<template>
  <div>
    <HomeSlideShow></HomeSlideShow>
    <!-- <FeaturedEvent></FeaturedEvent> -->
    <WatchNow></WatchNow>
    <ACCSection></ACCSection>
    <!-- <WeLiveFaithThrough></WeLiveFaithThrough> -->
    <WhatMakesUsUnique></WhatMakesUsUnique>
  </div>
</template>

<script>
import HomeSlideShow from "./HomeSlideShow/HomeSlideShow.vue";
// import WeLiveFaithThrough from "./WeLiveFaithThrough.vue";

import WhatMakesUsUnique from "./unique/WhatMakesUsUnique.vue";
import WatchNow from "../components/watch-now/WatchNow.vue";
import ACCSection from "./ACCSection.vue";
// import FeaturedEvent from "../events/events/BackToSchool/BackToSchool.vue";

export default {
  name: "Home",
  components: {
    HomeSlideShow,
    // WeLiveFaithThrough,
    WhatMakesUsUnique,
    WatchNow,
    ACCSection,
    // FeaturedEvent,
  },
};
</script>

<style scoped></style>
